import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import SettingsIndexView from '@/views/SettingsIndexView.vue'
import DataSyncIndexView from '@/views/DataSyncIndexView.vue'
import PhotosCreateFromCameraView from '@/views/PhotosCreateFromCameraView.vue'
import PhotosImportFromDeviceView from '@/views/PhotosImportFromDeviceView.vue'

// Import middlewares
import authMiddleware from '@/router/middlewares/auth-middleware'
import guestMiddleware from '@/router/middlewares/guest-middleware'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/photos/create-from-camera',
		name: 'photos.create-from-camera',
		component: PhotosCreateFromCameraView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/photos/import-from-device',
		name: 'photos.import-from-device',
		component: PhotosImportFromDeviceView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/settings',
		name: 'settings.index',
		component: SettingsIndexView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/data-sync',
		name: 'data-sync.index',
		component: DataSyncIndexView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/login',
		name: 'auth.login',
		component: LoginView,
		beforeEnter: guestMiddleware,
	},
]

const router = new VueRouter({
	routes
})

export default router
