import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import axios from 'axios'
import router from './router'
import localforage from 'localforage'
import { extendPrototype } from 'localforage-startswith'

// Configure axios base url
let currentUrl = window.location.href

if (currentUrl.indexOf("localhost") !== -1) {
	axios.defaults.baseURL = "http://localhost/api/v1"
} else if (currentUrl.indexOf("192.168.") !== -1) {
	axios.defaults.baseURL = "http://" + window.location.hostname + "/api/v1"
} else if (currentUrl.indexOf("staging") !== -1) {
	axios.defaults.baseURL = "https://staging.wrap360.eu/api/v1"
} else {
	axios.defaults.baseURL = "https://wrap360.eu/api/v1"
}

// Add a request interceptor to automatically set the token if the user is logged in
axios.interceptors.request.use(request => {
	return localforage.getItem("USER").then(user => {
		request.headers.Authorization = "Bearer " + user.token
		return Promise.resolve(request)
	}).catch(() => {
		return Promise.resolve(request)
	})
})

// Add a response interceptor to handle the common errors
axios.interceptors.response.use(response => response, error => {
	let message = ""

	if (error.code == "ERR_NETWORK") { // We are offline
		message = "Si è verificato un errore durante la richiesta; il dispositivo potrebbe essere offline."
	} else if (error.response.data.errors) {
		message = error.response.data.errors.join("\n")
	} else {
		message = "Si è verificato un errore durante la richiesta."
	}

	console.log(message, error)

	alert(message)

	return Promise.reject(error)
})

// Configure our database name
localforage.config({
	name: "Wrap360",
})

// Add startsWith to localforage
extendPrototype(localforage)

Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
