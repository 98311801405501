<template>
	<div id="home-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Home
			</h1>
		</div>

		<form>
			<div class="form-group mb-3">
				<label for="code" class="form-label">Codice</label>
				<div class="input-group">
					<input type="text" class="form-control">
					<button class="btn btn-primary" type="button">
						Barcode
					</button>
				</div>
			</div>
			<div class="form-group mb-3">
				<label for="set" class="form-label">Set</label>
				<input type="text" class="form-control">
			</div>
		</form>

		<hr>

		<router-link :to="{name: 'photos.create-from-camera'}" class="btn btn-primary mb-3 w-100">
			Fotocamera
		</router-link>

		<router-link :to="{name: 'photos.import-from-device'}" class="btn btn-primary mb-3 w-100">
			Importa da dispositivo
		</router-link>

		<hr class="mt-0">

		<router-link :to="{name: 'settings.index'}" class="btn btn-info mb-3 w-100">
			Impostazioni
		</router-link>

		<router-link :to="{name: 'data-sync.index'}" class="btn btn-info mb-3 w-100">
			Sincronizzazione dati
		</router-link>

		<hr class="mt-0">

		<p class="text-center">
			Connesso come <span class="text-primary" v-if="user">{{ user.username }}</span>
		</p>

		<button class="btn btn-danger w-100 mb-3" type="button" @click="performLogout">
			Logout
		</button>
	</div>
</template>

<script>
import localforage from "localforage"

export default {
	name: "HomeView",
	data () {
		return {
			loading: false,
			user: null,
		}
	},
	mounted () {
		this.loading = true

		Promise.all([
			localforage.getItem("USER").then(user => this.user = user || null),
		]).finally(() => this.loading = false)
	},
	methods: {
		performLogout () {
			localforage.removeItem("USER").then(() => this.$router.replace({ name: "auth.login" }))
		},
	}
}
</script>