import localforage from 'localforage'

export default async function (to, from, next) {
	let user = await localforage.getItem("USER")

	if (user) {
		return next(from.path)
	}

	return next()
}