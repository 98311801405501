<template>
	<div id="photos-import-from-device-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Importa da dispositivo
			</h1>
		</div>
	</div>
</template>

<script>
export default {
	name: "PhotosImportFromDeviceView",
	data () {
		return {
			loading: false,
		}
	},
	mounted () {
	},
	methods: {
	}
}
</script>
