<template>
	<div id="data-sync-index-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Sincronizzazione dati
			</h1>
		</div>

		<div v-if="loading">
			<div class="card mb-3">
				<div class="card-body">
					Caricamento in corso...

					<div class="progress mt-1">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<button type="button" class="btn btn-secondary w-100" @click="downloadData()">
				Aggiorna dati in cache
			</button>

			<hr>

			<button type="button" class="btn btn-info w-100 mb-0" :disabled="photosToBeSynced.length == 0" @click="syncPhotos()">
				Fotografie ({{ photosToBeSynced.length }})
			</button>
		</div>

		<hr>

		<div class="card border-danger mb-3">
			<div class="card-body">
				<h4 class="card-title text-red">
					Elimina dati locali
				</h4>

				<p class="card-text">
					Attenzione, questa azione non può essere annullata!<br>
					Tutti i dati salvati sul dispositivo, <strong>sincronizzati e non</strong>, verranno eliminati.
				</p>

				<button type="button" class="btn btn-danger w-100" @click="deleteLocalData()">
					Elimina dati locali
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import localforage from 'localforage'

import PhotosRepository from '@/repositories/photos-repository'

let photosRepository = new PhotosRepository()

export default {
	name: "DataSyncIndexView",
	data () {
		return {
			loading: false,
			photos: [],
			user: null,
		}
	},
	computed: {
		photosToBeSynced () { return this.photos.filter(p => p.synced_at == null) },
	},
	mounted () {
		this.loading = true

		Promise.all([
			localforage.getItem("USER").then(user => this.user = user || null),
			photosRepository.getAll().then(photos => this.photos = photos),
		]).finally(() => this.loading = false)
	},
	methods: {
		downloadData () {
			this.loading = true

			Promise.all([
			]).finally(() => this.loading = false)
		},
		syncPhotos () {
			this.loading = true

			photosRepository.syncAll()
				.then(photos => this.photos = photos)
				.finally(() => this.loading = false)
		},
		deleteLocalData () {
			let confirmation = confirm("Per procedere è richiesta una conferma. Continuare?")

			if (!confirmation) {
				return
			}

			this.loading = true

			Promise.all([
				photosRepository.deleteAll().then(photos => this.photos = photos),
				localforage.setItem("SETTINGS", {}),
			]).finally(() => this.loading = false)
		},
	}
}
</script>