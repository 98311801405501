import axios from "axios";
import localforage from "localforage";
import { currentDatetime } from "@/utils/datetime-utils";

export default class PhotosRepository {

	constructor () {
		this.repositoryNamespace = "PHOTOS"
	}

	async getAll () {
		return localforage.getItem(this.repositoryNamespace).then(photos => photos ?? [])
	}

	async create () {
		let photo = {
			// TODO: Add missing data
			synced_at: null,
		}


		if (navigator.onLine) {
			let requestParams = {
				// TODO: Add missing data
			}

			try {
				await axios.post("/photos/create", requestParams)
				photo.synced_at = currentDatetime()
			} catch (error) {
				// Some errors are handled with an interceptor
			}
		}

		let existingPhotos = await localforage.getItem(this.repositoryNamespace) ?? []
		existingPhotos.push(photo)

		return localforage.setItem(this.repositoryNamespace, existingPhotos)
	}

	async syncAll () {
		let existingPhotos = await localforage.getItem(this.repositoryNamespace) ?? []

		for (let i = 0; i < existingPhotos.length; i++) {
			let photo = existingPhotos[i]

			if (navigator.onLine && photo.synced_at == null) {
				let requestParams = {
					// TODO: Add missing data
				}

				try {
					await axios.post("/photos/create", requestParams)
					existingPhotos[i].synced_at = currentDatetime()
				} catch (error) {
					// Some errors are handled with an interceptor
				}
			}
		}

		return localforage.setItem(this.repositoryNamespace, existingPhotos)
	}

	// --------------------------------------------------

	async deleteAll () {
		return localforage.setItem(this.repositoryNamespace, [])
	}
}