<template>
	<div id="settings-index-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Impostazioni
			</h1>
		</div>

		<div v-if="loading">
			<div class="card mb-3">
				<div class="card-body">
					Caricamento in corso...

					<div class="progress mt-1">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<form>
				<fieldset>
					<div class="form-group mb-3">
						<label for="" class="form-label">Dispositivo da utilizzare</label>
						<select v-model="settings.selectedDeviceId" class="form-control" @change="saveSettings()">
							<option :value="null">--</option>
							<option v-for="device in availableDevices" :key="device.deviceId" :value="device.deviceId">
								{{ device.kind }}: {{ device.label}}
							</option>
						</select>
					</div>
					<div class="form-group mb-3">
						<label for="" class="form-label">Risoluzione</label>
						<select v-model="settings.selectedResolution" class="form-control" @change="saveSettings()">
							<option :value="null">--</option>
							<option v-for="resolution, index in availableResolutions" :key="index" :value="resolution">
								{{ resolution.width }}x{{ resolution.height }} ({{ resolution.ratio }})
							</option>
						</select>
					</div>
					<div class="form-group mb-3">
						<label for="" class="form-label">Qualità immagine</label>
						<select v-model="settings.imageQuality" class="form-control" @change="saveSettings()">
							<option :value="50">50%</option>
							<option :value="75">75%</option>
							<option :value="90">90%</option>
							<option :value="100">100%</option>
						</select>
					</div>
				</fieldset>
			</form>
		</div>
	</div>
</template>

<script>
import localforage from 'localforage'

export default {
	name: "SettingsIndexView",
	data () {
		return {
			loading: false,
			availableDevices: [],
			availableResolutions: [
				// 4:3
				{ratio: "4:3", width:  480, height:  360},
				{ratio: "4:3", width:  960, height:  720},
				{ratio: "4:3", width: 1920, height: 1440},

				// 16:9
				{ratio: "16:9", width:  640, height:  360},
				{ratio: "16:9", width:  720, height:  480},
				{ratio: "16:9", width: 1280, height:  720},
				{ratio: "16:9", width: 1920, height: 1080},
				{ratio: "16:9", width: 2560, height: 1440},
				{ratio: "16:9", width: 3840, height: 2160},
			],

			settings: {
				selectedDeviceId: null,
				selectedResolution: null,
				imageQuality: 100,
			},
		}
	},
	mounted () {
		this.loading = true

		Promise.all([
			localforage.getItem("SETTINGS").then(settings => Object.assign(this.settings, settings)),
			navigator.mediaDevices.enumerateDevices().then(devices => this.availableDevices = devices),
		]).finally(() => this.loading = false)
	},
	methods: {
		saveSettings () {
			localforage.setItem("SETTINGS", this.settings)
		}
	},
}
</script>
