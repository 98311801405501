<template>
	<div id="photos-create-from-camera-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Fotocamera
			</h1>
		</div>

		<video id="camera-preview" autoplay style="width: 100%"></video>
	</div>
</template>

<script>
import localforage from "localforage"

export default {
	name: "PhotosCreateFromCameraView",
	data () {
		return {
			loading: false,
			settings: {},
			selectedDevice: null,
		}
	},
	mounted () {
		this.loading = true

		localforage.getItem("SETTINGS")
			.then(settings => Object.assign(this.settings, settings))
			.then(() => {
				let deviceConstraints = {
					video: {
						deviceId: { exact: this.settings.selectedDeviceId },
						width:    { exact: this.settings.selectedResolution.width } ,
						height:   { exact: this.settings.selectedResolution.height },
					},
				}

				return navigator.mediaDevices.getUserMedia(deviceConstraints)
			}).then(device => {
				this.selectedDevice = device

				document.getElementById("camera-preview").srcObject = device
			}).finally(() => this.loading = false)
	},
	beforeDestroy () {
		if (this.selectedDevice) {
			this.selectedDevice.getTracks().forEach(track => track.stop())
		}
	},
	methods: {
	}
}
</script>
